<template>
    <section>
        <div class="d-flex justify-content-end">
            <button class="btn btn-primary" @click="goToEditPostContent()">Modifier le contenu de l'article</button>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <Content v-if="ulid" :ulid="ulid" :editable="false" page_name="blog" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Commentaires</h4>
                        <!-- my postcomments -->
                        <div class="add-items d-lg-flex">
                            <input v-model="myComments" type="text" class="form-control todo-list-input" placeholder="Qu'en pensez-vous?">
                            <button @click="postmycomment()"
                             class="add btn btn-gradient-primary font-weight-bold todo-list-add-btn ml-0 ml-lg-2 mt-2 mt-lg-0" id="mypostcomment">
                                <i class="mdi mdi-send"></i>
                            </button>
                        </div>

                        <div class="profile-feed">
                            <div v-if="postcomments.length==0">Il n'y a pas de commentaire</div>
                            <div class="d-flex align-items-start profile-feed-item" v-for="postcomment in postcomments" :key="postcomment.id">
                                <img src="../../../../assets/images/faces/face13.jpg" alt="profile" class="img-sm rounded-circle"/>
                                <div class="ml-4">
                                <h6>
                                   {{postcomment.user.lastname}} {{postcomment.user.firstname}}
                                    <small class="ml-4 text-muted pointer" @click="deleteComment(postcomment.ulid)"><i class="mdi mdi-delete mr-1"></i></small>
                                </h6>
                                <p>
                                    {{postcomment.comment}}
                                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import Content from '../preview/page-preview-d.vue'
export default {
    name:"postDetails",
    components:{Content},
    data(){
        return{
            // postcomments:[1,2,3],
            ulid:"",
            postcomments:[],
            mycomments:''
        }
    },
    methods:{
        goToEditPostContent(){
            this.$router.push({
                name:"page-preview",
                params:{
                    ulid:this.ulid,
                    page:"blog"
                }
            })
        },
        getPosts(ulid){
            axios.get(`posts/${ulid}`)
            .then(resGetPosts => {
                console.log(resGetPosts);
                this.postcomments = resGetPosts.data.postcomments
            })
            .catch(errGetPosts => console.log(errGetPosts))
        },
        postmycomment(){
            axios.post(`mypostcomments`,
            {   
                post:this.ulid,
                comment:this.myComments,
                datepublished:new Date(),
                datemodified: new Date()
            },
            {
                headers:{
                    "X-AUTH-TOKEN":localStorage.getItem('token')
                }
            })
            .then(resPostMyComment => {
                console.log(resPostMyComment)
                this.myComments=''
                this.getPosts(this.ulid)
            })
            .catch(errPostMyComment => console.error(errPostMyComment))
        },
        deleteComment(ulid){
            this.$swal({
            title: 'Voulez-vous supprimer ce commentaire?',
            text: "Votre action sera irréversible",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'annuler!',
            confirmButtonText: 'Oui, supprimer'
        }).then((result) => {
            axios.delete(`postcomments/${ulid}`)
            .then(resPostComment => {
                console.log({resPostComment})
            })
            .catch(errPostComment => {
                console.error(errPostComment)
            })
            if (result.value) {
            this.$swal(
                'Validé!',
                'Le commentaire a bien été supprimé',
                'success'
            )
            }
        })
        }
    },
    mounted(){
        this.ulid = this.$route.params.ulid,
        this.getPosts(this.ulid)
    }
}
</script>